import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/_admin": [3],
		"/_admin/crash": [4],
		"/_admin/import_prompts": [5],
		"/_admin/keyboard": [6],
		"/_admin/library": [7],
		"/_admin/loading_reasons": [9],
		"/_admin/loading": [8],
		"/account": [10],
		"/account/login": [~11],
		"/account/registration": [~12],
		"/dashboard": [~13],
		"/draw/[id=integer]": [~14],
		"/guess/[id=integer]": [~15],
		"/offline": [16],
		"/play/[[category=slug]]": [~17],
		"/share/[id=integer]": [~18],
		"/[[id=integer]]": [~2]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';